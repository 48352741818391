<template>
  <v-container class="p-5 text-center">
    <v-img src="@/assets/logout.svg">
    </v-img>
    <v-overlay :value="true">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
      <small class="d-block mt-3">Loging out please wait....</small>
    </v-overlay>
  </v-container>
</template>
<script>
export default {
  created() {
    this.axios.post("/logout").then(() => {
      this.$user.Logout();
      this.$router.push({ name: "Home" });
    });
  },
};
</script>